import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import LunchContent from '../components/ContentPages/LunchContent';

function LunchPage({ data, location }) {
  const sanityContent = data.lunchpage;


  return (
    <Layout location={location} lang="en">
      {sanityContent && <LunchContent sanityContent={sanityContent} lang="En" />}
    </Layout>
  );
}

export const query = graphql`
  query LunchPageEn {
    lunchpage: sanityPage(id: { eq: "-d1a9addb-3e3c-5f33-bfca-ba8b36f07fb9" }) {
      id
      titleEn
      description {
        _rawTextEn
      }
      mainImage {
        asset {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
        alt
      }
      contentSections {
        ... on SanityMenu {
          id
          menuSectionsOne {
            headingEn
            menuItems {
              descriptionEn
              nameEn
              price
              allergensEn
              hidden
            }
          }
          menuSectionsTwo {
            headingEn
            menuItems {
              descriptionEn
              nameEn
              price
              allergensEn
              hidden
            }
          }
        }
      }
    }
  }
`;

export default LunchPage;
